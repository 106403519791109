<template>
  <div class="control-table-footer df jc-sb ai-c">
    <div class="control-table-footer__count-car df ai-fe fs-10 ttu ls-5 fw-5">
      <span class="control-table-footer__text m-r-16">{{ textQuantity }}</span>
      <span class="control-table-footer__count fs-12 fw-b">
        {{ paginationConfig.totalCount }}
      </span>
    </div>

    <div class="control-table-footer__control df ai-c fs-12 ls-5 fw-5">
      <div class="control-table-footer__btns">
        <IqPagination
          v-if="paginationConfig"
          :page-size="paginationConfig.perPage"
          :page-count="paginationConfig.pageCount"
          :pager-count="pager"
          :current-page="paginationConfig.currentPage"
          :total="paginationConfig.totalCount"
          @onChangePage="changePage"
        />
      </div>

      <div class="control-table-footer__select">
        <div class="m-r-9 fw-5">На одной странице:</div>
        <IqSelect
          v-model.number="perPage"
          :options="perPageList"
          key-label="label"
          key-value="value"
          class="control-table-footer__select-input"
        />
        <div class="m-l-9">{{ itemNames }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { perPageList } from '../data/constants'
import IqPagination from '@/UI/pagination/IqPagination'
import IqSelect from '@/UI/select/IqSelect'

export default {
  name: 'TableFooter',
  components: { IqSelect, IqPagination },
  props: {
    pagination: { type: Object, default: () => {} },
    pager: { type: Number, default: 7 },
    textQuantity: { type: String, default: 'Всего:' },
    itemNames: { type: String, default: 'автомобилей' },
  },
  data() {
    return { perPageList }
  },
  computed: {
    perPage: {
      get() {
        return this.pagination.perPage
      },
      set(size) {
        this.$emit('size', size)
      },
    },
    paginationConfig() {
      return this.pagination && typeof this.pagination.totalCount !== undefined
        ? this.pagination
        : { totalCount: '-', perPage: ' - ' }
    },
    showPagination() {
      return (
        this.paginationConfig &&
        (this.paginationConfig.totalCount || 0) /
          (this.paginationConfig.perPage || 1) >
          1
      )
    },
  },
  methods: {
    changePage(page) {
      this.$emit('page', page)
    },
  },
}
</script>

<style lang="sass">
.control-table-footer
  padding: 9px 18px 9px 30px
  border-radius: 0 0 6px 6px
  color: $color-control-secondary
  font-family: Roboto, sans-serif
  +to(620px)
    flex-wrap: wrap
    padding: 15px 18px 15px 30px

  &__text
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $main-font

  &__count
    background: $color-white
    border: 1px solid #DCDFE6
    border-radius: 4px
    padding: 5px 12px
    font-weight: bold
    font-size: 16px
    line-height: 18px
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $main-font

  &__count-car
    display: flex
    justify-content: flex-start
    align-items: center
    +to(620px)
      order: 2
      flex: 0 0 100%
      width: 100%

  &__control
    +to(620px)
      order: 1
      flex: 0 0 100%
      width: 100%
      margin-bottom: 12px
      justify-content: space-between
    +to(430px)
      flex-wrap: wrap

  &__select
    display: flex
    justify-content: flex-start
    align-items: center
    font-weight: 500
    font-size: 14px
    line-height: 22px
    font-feature-settings: 'tnum' on, 'lnum' on
    color: #606266
    +to(768px)
      margin-right: 10px
    +to(430px)
      order: 1
    .el-input
      font-size: $fs-12
      font-weight: 600
      letter-spacing: 0.05em
      color: #606F89
    .el-input__inner
      padding: 0 15px 0 2px
      border: none
      height: auto
      line-height: 1.2
      width: 45px
    .el-input__icon
      line-height: 1
    .el-icon-arrow-up:before
      content: "\e78f"

  &__select-input
    width: auto !important
    background: $color-white
    border: 1px solid #DCDFE6
    border-radius: 4px
    padding: 5px 0 5px 12px
    .el-input__inner
      font-weight: 500
      font-size: 14px !important
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266

  &__table-position
    margin-right: 50px
    +to(768px)
      margin-right: 10px
    +to(430px)
      order: 3
      flex: 0 0 100%

  &__btns
    +to(430px)
      order: 2
    +to(350px)
      flex: 0 0 100%
      margin: 10px 0
    .el-button.is-circle
      //padding: 6px
      border: none
    .el-button + .el-button
      margin-left: 38px
      +to(768px)
        margin-left: 10px
</style>
